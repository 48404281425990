import './App.css';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom' 
import React from 'react';
import HeroSection from './components/HeroSection';
import Footer from './components/Footer';
import WashTypes from './components/WashTypes';
import Cards from './components/Cards';
import Quote from './components/Quote'
import About from './components/About'

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <HeroSection />
        <Cards/>
        <WashTypes/>
        <Quote/>
        <About/>
        <Routes>
          <Route path='/' exact />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
