import React from 'react'
import './Quote.css';

function Quote() {
  return (
    <div id='quote' className='quote-container'>
        <h1>Get Your Free Quote TODAY!</h1>
        <div className='quote-wrapper'>
        <iframe class="airtable-embed" src="https://airtable.com/embed/shrc14th4XmWZbyY8?backgroundColor=blue" frameborder="0" onmousewheel="" width="100%" height="533"></iframe>
        </div>
    </div>
  )
}

export default Quote