import React from 'react';
import './About.css';

function About() {
  return (
    <div id='about' className='about-container'>
        <h1>About Us</h1>
        <div className='about-wrapper'>
          <p>
            J&J Washing is a business that is dedicated to improving the community. Founded in 2023 by two Westford Academy alumni, Jacob Wilson and Joey Bienkiewicz, we strive to make genuine connections with our customers and provide the best quality service.
          </p>
          <p>
            At J&J Washing, we believe that communication is key. We take the time to listen to your needs and preferences before starting any project, whether you need residential or commercial washing services. Our team of highly skilled technicians uses state-of-the-art equipment and cleaning solutions to ensure that your property looks its absolute best.
          </p>
          <p>
          We are committed to providing the best quality service possible, and we are always looking for ways to improve. Our goal is to exceed your expectations, every time. When you choose J&J Washing, you can rest assured that you are getting a partner who is dedicated to improving your community and providing top-quality service. Contact us today to see how we can help you!
          </p>
        </div>
    </div>
  )
}

export default About;