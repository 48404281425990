import React from 'react';
import '../App.css';

import './HeroSection.css';

function HeroSection() {
  return (
    <>
      <div className='hero-container'>
        <figure className='hero-figure'>
          <figcaption>
            <p> Power Washing Done Right</p>
          </figcaption>
          <img src={require('../assets/shutterstock_53434123.jpg')} alt='hero' className='hero-image'></img>
        </figure>
      </div>
      <div className='hero-bottom-container'>
        <div className='hero-bottom-wrapper'>
          <div className='hero-bottom-items'>
            <h1>Expert Washers</h1>
            <i class="fa-solid fa-droplet"></i>
          </div>
          <div className='hero-bottom-items'>
            <h1>High Quality Service</h1>
            <i class="fa-solid fa-thumbs-up fa-2xl"/>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;