import React from 'react';
import './Footer.css'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer>
      <div className="footer-container">
        <div className="contacts">
          <h3>J&J Washing</h3>
          <h3>washing.jnj@gmail.com</h3>
          <h3>Westford, MA 01886</h3>
        </div>
        <div className="socials">
          <h3>Follow Us</h3>
          <ul>
            <li>
              <Link to='https://www.linkedin.com/company/j-j-washing/'>
                <i class='fa-brands fa-linkedin'></i>
              </Link>
            </li>
            <li>
              <Link to='https://www.facebook.com/people/JJ-Washing/100091326908759/'>
                <i class='fa-brands fa-facebook'></i>
              </Link>
            </li>
            <li>
              <Link to='https://www.tiktok.com/@jnj_washing'>
                <i class='fa-brands fa-tiktok'></i>
              </Link>
            </li>
            <li>
              <Link to='https://www.instagram.com/jnjwashing/'>
                <i class='fa-brands fa-instagram'></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <p className="text-center">&copy; 2023 J&J Washing. All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;