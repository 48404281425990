import React from 'react';
import './WashTypes.css';

function WashTypes() {
  return (
    <div className='washtype-container'>
      <div className='washtype-wrapper'>
        <div className='washtype-item'>
          <h1 className = 'washtype-subtitle'>Pressure Washing</h1>
          <div className='washtype-desc'>
          <p>Make your property shine with our power washing services!</p>
            <ul>
              <li>Our high-pressure water streams can remove dirt, grime, stains, and other contaminants from surfaces, leaving them looking brand new. </li>
              <li>We specialize in cleaning exterior surfaces such as buildings, decks, patios, driveways, and walkways.</li>
              <li>Trust our highly skilled technicians to safely and effectively clean your property.</li>
            </ul>
          </div>
        </div>
        <div className='washtype-item'>
          <h1 className = 'washtype-subtitle'>Soft Washing</h1>
          <div className='washtype-desc'>
            <p>Our soft washing services are gentle yet effective in cleaning your property!</p>
            <ul>
              <li>We use a low-pressure water spray and specialized cleaning solutions to remove dirt, algae, and other contaminants from surfaces. </li>
              <li>Our services can be applied to various surfaces such as roofs, siding, and decks.</li>
              <li>You can trust us to leave your property looking like new.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WashTypes