import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div id ='cards' className='cards'>
      <h3>We Are So Glad You Are Here...</h3>
      <h1>How Can We Best Serve You?</h1>
      <div className='cards__container'>
        <ul className='cards__items'>
          <CardItem
            src={require('../assets/shutterstock_1383157574.jpg')}
            text='House Washing'
            path='/services'
          />
          <CardItem
            src={require('../assets/shutterstock_1505384501.jpg')}
            text='Roof Washing'
            path='/services'
          />
          <CardItem
            src={require('../assets/shutterstock_1862273653.jpg')}
            text='Driveways And Walkways'
            path='/services'
          />
          <CardItem
            src={require('../assets/shutterstock_144849883.jpg')}
            text='Commerical Washing'
            path='/services'
          />
        </ul>
      </div>
    </div>
  );
}

export default Cards;